import './index.html';
import './style.scss';
import {gsap} from "gsap";
import {imageLazyLoading} from '../../scripts/functions/imageLazyLoading';
import {animations} from '../../scripts/general/animations';

/**
 *
 * @param block {HTMLElement}
 * @returns {Promise<void>}
 */
export default async (header) => {

  const menuItems = header.querySelectorAll('.menu-item');
  const sticky = "sticky";
  const fixedTop = 'fixed';
  // let burger_menu = header.querySelector('.burger-menu');
  //let navbar = header.querySelector('.navbar');
  // burger_menu.addEventListener('click', function () {
  //   document.documentElement.classList.toggle('modal-opened');
  //   burger_menu.classList.toggle("change");
  //   navbar.classList.toggle("navbar-active");
  //   header.classList.toggle('header-active');
  // });

// region sticky


  //endregion sticky


  const burgerMenu = header.querySelector('.burger-menu');
  // const menuLinks = header.querySelector('.navbar');
  let navbar = header.querySelector('.navbar');


  if (!burgerMenu) return;
  const burgerTl = gsap.timeline({paused: true});
  const burgerSpans = burgerMenu.querySelectorAll('span');
  gsap.set(burgerSpans, {transformOrigin: 'center'});
  burgerTl
    .to(burgerSpans, {
      y: gsap.utils.wrap([`0.8rem`, 0, `-0.8rem`]),
      duration: 0.25,
    })

    .set(burgerSpans, {autoAlpha: gsap.utils.wrap([1, 0, 1])})
    .to(burgerSpans, {rotation: gsap.utils.wrap([45, 0, -45])})
    .set(burgerSpans, {rotation: gsap.utils.wrap([45, 0, 135])})

  burgerMenu.addEventListener('click', function () {

    if (burgerMenu.classList.contains('burger-menu-active')) {
      // region allow page scroll
      document.documentElement.classList.remove('modal-opened');
      // endregion allow page scroll
      burgerMenu.classList.remove('burger-menu-active');
      // menuLinks.classList.remove('header-links-active');
      navbar.classList.remove("navbar-active");
      header.classList.remove('header-active');
      // const activeItem = header.querySelector('.menu-item-has-children.menu-item-active');
      // activeItem && activeItem.classList.remove('menu-item-active');
      burgerTl.reverse();
    } else {
      burgerMenu.classList.add('burger-menu-active');
      // menuLinks.classList.add('header-links-active');
      navbar.classList.add('navbar-active');
      header.classList.add('header-active');
      burgerTl.play();
      // region prevent page scroll
      document.documentElement.classList.add('modal-opened');
      // endregion prevent page scroll
      gsap.fromTo(navbar.querySelectorAll('.sub-menu'), {
        y: 30,
        autoAlpha: 0,
      }, {
        y: 0,
        autoAlpha: 1,
        stagger: .05,
        duration: .4,
        delay: .5,
      });
    }
  });


  // region open sub menu in responsive

  const headerLinks = header.querySelectorAll('.primary-menu .has-megaMenu .svg-wrapper');
  const megamenu = header.querySelectorAll(".has-megaMenu");
  headerLinks.forEach((menuItem) => {
    // const menuItemBody = menuItem.querySelector('.sub-menu');
    const parentOfLink = menuItem.closest('.menu-item');
    const menuItemBody = parentOfLink.querySelector('.sub-menu');
    const meuItemLink = parentOfLink.querySelector('.item-1');
    const menuLink = parentOfLink.querySelector('.menu-link');

    const closeMenuDropdown = () => {
      parentOfLink?.classList.remove('active-header-link');
      gsap.to(menuItemBody, {height: 0});
      menuLink.setAttribute("aria-expanded", "false");
    };
    const toggleMenuDropdown = () => {
      const isOpened = parentOfLink?.classList.toggle('active-header-link');
      if (!isOpened) {
        closeMenuDropdown();
      } else {
        gsap.to(Array.from(megamenu).map(otherMenuItem => {
          const otherMenuItemBody = otherMenuItem.querySelector('.sub-menu');
          if (otherMenuItemBody && parentOfLink !== otherMenuItem) {
            otherMenuItem?.classList.remove('active-header-link');
            gsap.set(otherMenuItem, {zIndex: 1});
            if (menuLink.getAttribute("aria-expanded") === "false") {
              menuLink.setAttribute("aria-expanded", "true");
            }

          }
          return otherMenuItemBody;
        }), {height: 0});
        gsap.set(parentOfLink, {zIndex: 2});
        gsap.to(menuItemBody, {height: 'auto'});
      }
    };

    menuItem?.addEventListener('click', toggleMenuDropdown);
    if (parentOfLink.classList.contains('has-megaMenu')) {
      document.addEventListener('keydown', (e) => {
        e.stopImmediatePropagation();
        // if (+e.keyCode === 32) {
        //   e.preventDefault();
        //
        //   let firstLi = menuItem.nextElementSibling.firstElementChild.firstElementChild;
        //   firstLi.focus();
        //   toggleMenuDropdown();
        //
        // } else if (+e.keyCode === 27) {
        //   closeMenuDropdown();
        // }
        if (+e.keyCode === 38) {
          e.preventDefault();
          const el = document.activeElement.previousElementSibling;
          if (el) {
            el.focus();
          }
        }
        if (+e.keyCode === 40) {
          e.preventDefault();
          console.log('next');
          const el = document.activeElement.nextElementSibling;
          if (el) {
            el.focus();
          }
        }

      });
    }
  });


  window.addEventListener('scroll', () => {
    for (let i = 0; i <= menuItems.length; i++) {
      if (menuItems[i]?.classList.contains('active-header-link') && header.classList.contains(sticky)) {
        menuItems[i]?.classList.remove('active-header-link');
        const menuItemBody = menuItems[i].querySelector('.sub-menu');
        gsap.to(menuItemBody, {height: 0});
      }
    }
  });

// endregion open sub menu in responsive


  // //region desktop search
  // const descktopMedia = window.matchMedia('(min-width: 600px)');
  //
  // let input = header.querySelector('#s');
  // if(descktopMedia.matches){
  //   input.addEventListener('input', () => {
  //     input.style.width = `${input.value.length +1}ch`;
  //   });
  // }


  const descktopMedia = window.matchMedia('(min-width: 600px)');
  let input = header.querySelector('#s');
  let searchIcon = header.querySelector('.lg-search .search-icon');
  if (descktopMedia.matches) {
    searchIcon.addEventListener('click', () => {
      input.classList.toggle('open-input');
    });
  }


  //endregion desktop search


  let lastScroll = 0;
  window.addEventListener("scroll", () => {
    const currentScroll = window.scrollY;
    if (currentScroll <= 50) {
      header.classList.add(fixedTop);
      header.classList.remove(sticky);
    } else {
      header.classList.remove(fixedTop);
      header.classList.toggle(sticky, currentScroll > lastScroll);
    }
    lastScroll = currentScroll;
  });


  window.addEventListener('click', (e) => {
    for (let i = 0; i <= menuItems.length; i++) {
      let any = e.target.closest('.has-megaMenu');
      if (!any) {
        if (menuItems[i]?.classList.contains('active-header-link')) {
          menuItems[i]?.classList.remove('active-header-link');
          const menuItemBody = menuItems[i].querySelector('.sub-menu');
          gsap.to(menuItemBody, {height: 0});
        }
      }
    }
  });

  let sub_menus = header.querySelectorAll('.list-items');
  sub_menus.forEach((sub_menu) => {
    let sub_menu_links = sub_menu.querySelectorAll('.item-1 .paragraph ');
    sub_menu_links.forEach((menuLink) => {
      menuLink.addEventListener('focus', function () {
        console.log(menuLink);
        let foucs_item = menuLink.closest('.sub-menu');
        foucs_item.classList.add('fouc-item');
      });
    });
  });


  let mainMenu = header.querySelectorAll(".main-menu .menu-item");
  let subMenu = header.querySelectorAll(".list-items");
  let subMenuLinks = header.querySelectorAll(".header-sublink");

  // Show/hide submenus using mouse or Enter key
  // mainMenu.forEach(level => level.addEventListener("keydown", function (e) {
  //   e.stopImmediatePropagation();
  //  if(level.classList.contains('menu-item')){
  //    level.nextElementSibling.firstElementChild.focus();
  //    // console.log(level.nextElementSibling.firstElementChild);
  //    // console.log( level.nextElementSibling);
  //     if (+e.keyCode === 37){
  //       e.preventDefault();
  //       const el =level.previousElementSibling;
  //       console.log(el);
  //       if (el) {
  //         console.log('prev');
  //         el.focus();
  //       }
  //     }
  //     if (+e.keyCode === 39) {
  //       e.preventDefault();
  //       const el = level.nextElementSibling;
  //       console.log(el);
  //       if (el) {
  //         console.log('next');
  //         el.focus();
  //       }
  //     }
  //   }
  //
  // }));


  // header.classList.toggle('sticky', window.scrollY >= 20);
  // window.addEventListener('scroll', function () {
  //   header.classList.toggle('sticky', window.scrollY >= 20);
  // });


  animations(header);
  imageLazyLoading(header);
};

